body {
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
  /* font-family: 'Open Sans', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  color: rgb(130, 136, 131);
}
i,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  padding: 0;
  margin: 0;
}
input:focus,
select:focus,
textarea:focus {
  outline: 0px;
}
.row,
.col-6,
.col-4 {
  margin: 0;
  padding: 0;
}
.Announcement {
  /* background-color: #1cb803;   */
  background-color: #000000;
  padding: 10px 0px 6px 0px;
}
.Announcement .col-md-6 p {
  color: white;
  margin-right: 6rem;
  font-size: 15px;
}
.Announcement .col-12 a {
  color: white;
  margin-right: 2rem;
  font-size: 15px;
}
/* Header */
.header {
  padding: 20px 0;
  margin-top: 20px;
  background-color: #000000;
}

.pc-header {
  padding-top: 15px;
  color: #000000;
}

.pc-header.home-nav .nav-link {
  color: #ffffff !important; /* White on the home page */
}

.pc-header .nav-link {
  color: #000000; /* Default black for other pages */
  font-weight: bold;
  transition: color 0.3s ease;
}

.pc-header .nav-link:hover {
  color: #cccccc !important; /* Slightly darker on hover */
}
.nav-link-white, .btn-link-white {
  color: #ffffff !important;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-link-black, .btn-link-black {
  color: #000000 !important;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-link-white:hover, .btn-link-white:hover {
  color: #cccccc !important; /* Slightly darker on hover for visual feedback */
}

.nav-link-black:hover, .btn-link-black:hover {
  color: #333333 !important; /* Darker on hover for visual feedback */
}

.pc-header .btn-link {
  color: #ffffff;
  text-decoration: none;
  margin-right: 15px;
  font-weight: bold;
}

.pc-header .btn-link:hover {
  color: #cccccc;
}

.pc-header .cart-icon {
  color: #ffffff;
}
.cart-icon-white {
  color: #ffffff !important;
  transition: color 0.3s ease;
}

.cart-icon-black {
  color: #000000 !important;
  transition: color 0.3s ease;
}

.cart-icon-white:hover {
  color: #cccccc !important; /* Lighter shade on hover for feedback */
}

.cart-icon-black:hover {
  color: #333333 !important; /* Darker shade on hover for feedback */
}

/* Ensure dropdown menu text is visible */
.pc-header .dropdown-menu {
  background-color: #000000;
}

.pc-header .dropdown-item {
  color: #ffffff !important;
}

.pc-header .dropdown-item:hover {
  background-color: #333333;
}
.mobile-header{
  padding-top: 10px;
}
.navbar-brand img {
  width: 60%;
}
.input-group {
  height: 50px;
  width: 90%;
}
.search-button {
  width: 25%;
  background-color: black;
  color: white;
  border: 0;
  text-transform: uppercase;
  font-size: 12px;
}
.Login-Register a {
  text-transform: uppercase;
  font-size: 15px;
  margin-right: 2rem;
  position: relative;
  
}
.Login-Register a:nth-last-child(1) {
  margin-right: 0rem;
}
.badge {
  background-color: red;
  position: absolute;
  top: 5px;
  border-radius: 100px;
  padding: 5px 7px;
  font-size: 12px;
}
.name-button {
  padding: 5px 15px;
  border: 1px solid #e4e4e4;
  background-color: white;
  border-radius: 2px;
  margin: 0 15px;
}

.dropdown-menu {
  background-color: black !important;
  border: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}
.dropdown-menu a {
  padding: 10px 20px !important;
  color: #ffffff !important;
  margin: 0 !important;
}
.dropdown-menu a:hover {
  background-color: #788079 !important;
  color: #ffffff !important;
}

/* Mobile Header Styles */
.menu-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.menu-button-white {
  color: #ffffff;
}

.menu-button-black {
  color: #000000;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 1001;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px 0 0 10px;
}

.mobile-menu.open {
  transform: translateX(0);
}

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.mobile-menu-content {
  position: relative;
  z-index: 1002;
}
.cart-mobile-icon{
  margin-right: 15px;
}
.mobile-badge{
  background-color: red;
  position: absolute;
  top: 5px;
  border-radius: 10px;
  padding: 1px 3px;
  font-size: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  color: #333;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin-top: 60px;
  text-align: left;
}

.nav-item {
  margin: 15px 0;
}

.mobile-nav-link {
  font-size: 18px;
  color: #333;
  text-decoration: none;
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.mobile-nav-link:hover {
  background-color: #f5f5f5;
  color: #000;
}

.mobile-header {
  display: none;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); /* Default position */
  }
  40% {
    transform: translateY(-5px); /* Move up */
  }
  60% {
    transform: translateY(-5px); /* Move slightly up */
  }
}
.button-container {
  position: absolute;
  bottom: 100px; 
  left: 50%;
  transform: translateX(-50%);
}
.shop-my-art-button {
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px; 
  border: none;
  cursor: pointer;
  font-size: 18px; 
  font-weight: 700;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
  transition: box-shadow 0.3s ease;
  animation: bounce 2s infinite;
}


/* SHOP */
.section {
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 6%;
  padding-top: 2%;
  text-align: center;
}
.sidebar_item {
  margin-bottom: 35px;
}
.sidebar_item h4 {
  color: #1c1c1c;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 25px;
}
.sidebar_item ul {
  margin: 0;
  padding: 0;
}
.sidebar_item ul li {
  list-style: none;
  display: flex;
}

.sidebar_item ul li a {
  font-size: 16px;
  color: #000000;
  line-height: 45px;
  display: block;
  font-family: "Open Sans", sans-serif;
}
.sidebar_item ul li img {
  width: 25px;
  object-fit: contain;
  margin-left: 20px;
}
.shop {
  margin-bottom: 50px;
}
.shop:hover {
  transform: scale(1.05);
}
.border-product {
  padding: 15px;
  /* border: 1px solid #b8b7b7; */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.shopBack {
  height: 250px;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #f3fbf7;
}
.shopBack img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}
.shoptext {
  padding-top: 10px;
}
.shoptext p a {
  color: #252525;
}
.shoptext h3 {
  color: #252525;
  margin-top: 13px;
  font-weight: bold;
  font-size: 19px;
}
.rating {
  margin: 5px 0;
}
.rating i {
  font-size: 12px;
  color: #ebb450;
}
.rating span {
  margin-left: 10px;
}
/* New Shop Intro Section */
.shop-intro {
  text-align: center;
  margin: 50px 20px;
  padding: 20px;
  background: linear-gradient(135deg, #f3f3f3, #e0e0e0);
  border-radius: 15px;
  position: relative;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.shop-intro h2 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #000000;
  font-weight: 700;
}

.shop-intro p {
  font-size: 18px;
  color: #000000;
  max-width: 600px;
  margin: 0 auto 30px;
  line-height: 1.6;
}

.shop-now-btn {
  background-color: #252525;
  color: #ffffff;
  padding: 15px 30px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.shop-now-btn:hover {
  background-color: #333333;
}

/* END OF SHOP */

/* NEWSLATTER */
/* .subscribe-section {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.226), rgba(0, 0, 0, 0.226)),
    url("")
      no-repeat scroll center / cover;
  padding: 100px 0 100px;
} */
.subscribe-head h2 {
  color: #fff;
  font-size: 25px;
  font-weight: bolder;
  letter-spacing: 0;
  line-height: 30px;
  margin: 0 0 13px;
  text-transform: uppercase;
}
.subscribe-head p {
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 39px;
}
.subscribe-head {
  text-align: center;
}
.form-section input[type="email"] {
  border: 0 none;
  border-radius: 25px;
  color: #9b9b9b;
  font-size: 16px;
  font-style: normal;
  height: 51px;
  letter-spacing: 0;
  text-align: center;
  width: 460px;
}
.form-section input[type="submit"] {
  background: #000000;
  border: 0 none;
  border-radius: 25px;
  color: #fff;
  height: 52px;
  letter-spacing: 0;
  margin-left: -60px;
  padding: 0 58px;
  text-transform: uppercase;
  font-size: 12px;
}
/* CONTACT */
.contactInfo {
  padding: 70px 0;
}
.contact-Box,
.box-info,
.info-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-info {
  width: 80%;
  flex-direction: column;
  padding: 40px 0;
  box-shadow: 0px 0px 27px 0px rgb(243, 243, 243);
}
.info-image {
  width: 50px;
  height: 50px;
  border: 2px solid #000000;
  border-radius: 100px;
  font-size: 20px;
  color: #000000;
}
.box-info h5 {
  font-weight: bold;
  font-size: 19px;
  margin-top: 20px;
  margin-bottom: 4px;
}
.box-info p {
  font-size: 16px;
}


/* SINGLE PRODUCT */
.single-product {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #000;
  color: #fff;
  margin-top: 15px;
}
.full-width-bg {
  width: 100vw;
  background-color: #000;
  color: #fff;
  margin-left: calc(50% - 50vw);
}

.single-image {
  width: 90%;
  padding: 20px;
}

.single-image img {
  width: 100%;
  height: auto;
  border: 5px solid #fff;
}

.product-info {
  width: 45%;
}

.product-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.product-price {
  font-size: 18px;
  margin-bottom: 20px;
}

.product-options, .product-sizes {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.option-btn, .size-btn {
  padding: 5px 10px;
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
}

.add-to-cart-btn {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  color: #000;
  border: none;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 20px;
}

.product-description {
  font-style: italic;
  line-height: 1.5;
}


@media (max-width: 768px) {
  .single-product-content {
    flex-direction: column;
  }
  .single-image, .product-info {
    width: 100%;
    height: auto;
  }
}
/**/
.product-name {
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 30px;
}

.display-flex {
  display: flex;
}
.product-info {
  width: 100%;
}
.product-dtl p {
  line-height: 24px;
  color: #7a7a7a;
}
.product-count {
  border: 2px solid #f3f3f3;
  border-radius: 5px;
  margin-top: 40px;
}
.product-count .flex-box {
  padding: 15px 25px;
  border-bottom: 2px solid #f3f3f3;
}
.product-count .flex-box h6 {
  font-size: 16px;
}
.product-count .flex-box span {
  font-weight: 600;
  color: #000000;
}
.product-count .flex-box select {
  background-color: #f3f3f3;
  width: 100px;
  height: 40px;
  text-align: center;
  border: 0px;
  border-radius: 5px;
}
.round-black-btn {
  border-radius: 4px;
  background: #000000;
  color: #fff;
  width: 100%;
  height: 50px;
  border: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  transition: all 0.5s ease-in-out 0s;
}
.round-black-btn:hover,
.round-black-btn:focus {
  background: #788079;
}

/* LOGIN */
.login-center {
  padding: 50px 0;
}
.Login,
.Login2 {
  background: #fff;
  border-radius: 5px;
  position: relative;
  padding: 35px 30px 30px;
  box-shadow: 0 1px 11px rgba(168, 168, 168, 0.27);
  text-align: center;
}
.Login input {
  width: 100%;
  padding: 20px 20px;
  margin-top: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  color: #8a8a8a;
}
.Login input:focus {
  outline: 0px;
}
.Login button,
.Login2 button {
  width: 100%;
  margin-top: 20px;
  padding: 20px 0;
  border: 0;
  background-color: #000000;
  border-radius: 5px;
  text-transform: uppercase;
  color: white;
}
.Login button a,
.Login2 button a {
  text-transform: uppercase;
  color: white;
}
.Login p {
  margin-top: 30px;
}
.Login p a {
  color: #7a7a7a;
}
/* PROFILE */
.author-card {
  position: relative;
}
.author-card-cover {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-image: url("https://gust.com/assets/blank_slate/Gust_Profile_CoverPhoto_Blank-21edf1e2890708d5a507204f49afc10b7dc58eb7baea100b68a1bc2c96948297.png");
}
.author-card-profile {
  padding: 20px 10px;
}
.author-card-avatar {
  padding: 0 20px;
}
.author-card-avatar img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 100px;
  margin-top: -60%;
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.377);
}
.wizard .nav button {
  padding: 15px 20px;
  font-size: 13px;
  background-color: #ffffff;
  color: black;
  width: 100%;
  text-align: start;
  border-radius: 0;
  text-transform: uppercase;
  border-top: 1px solid #e4e4e4;
}
.wizard .nav .active {
  background-color: #e5fee9;
  color: black;
}
.badge2 {
  width: 20px;
  height: 20px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #ffffff;
}
.form {
  padding: 20px 0;
}
.form label {
  padding: 10px 0;
  color: #8a8a8a;
  text-transform: uppercase;
  font-size: 13px;
}
.form input {
  padding: 20px 10px;
  font-size: 17px;
  background-color: #e5fee9;
}
.form-container button {
  padding: 15px 0;
  border: 0px;
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #000000;
  color: #ffffff;
}
/* orders */
.link {
  color: blue !important;
  border-bottom: 1px solid blue;
  padding-bottom: 5px;
  font-size: 15px;
}
.link:hover {
  color: blue;
}
.table-responsive {
  width: 100%;
}
/* CART */
.cart-iterm {
  margin: 40px 0;
  padding: 20px;
  background-color: white;
  box-shadow: 0 12px 20px 1px rgba(64, 64, 64, 0.09);
  position: relative;
}

.remove-button {
  background-color: red;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  position: absolute;
  top: -10px;
  left: -10px;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
}
.cart-image img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}
.cart-text a h4,
.cart-price h4 {
  font-size: 17px;
  font-weight: bold;
}
.cart-text p {
  margin-top: 10px;
  font-size: 15px;
  color: #7a7a7a;
}
.cart-qty select {
  width: 100%;
  font-size: 15px;
  padding: 15px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
}
.cart-qty h6,
.cart-price h6 {
  margin-bottom: 15px;
  color: #8c8c8c;
  font-size: 13px;
  text-transform: uppercase;
}
.total {
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: end;
  padding: 0 30px;
}
.total .sub {
  color: #8c8c8c;
  font-size: 20px;
  text-transform: uppercase;
  margin-right: 20px;
}
.total .total-price {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}
.cart-buttons {
  margin: 90px 0;
}
.cart-buttons a button,
.cart-buttons div button {
  width: 80%;
  padding: 15px 0;
  border: 0px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #000000;
  color: #ffffff;
}
.cart-buttons a:nth-child(1) button {
  background-color: #000000;
}
/* FOOTER */
.footer {
  background-color: #000000;
  color: #ffffff;
  padding: 50px 0 20px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-logo img {
  max-width: 100px;
  margin-bottom: 10px;
}

.footer-section {
  margin-bottom: 20px;
}

.footer-section h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 5px;
}

.footer-section ul li a {
  color: #ffffff;
  text-decoration: none;
}

.footer-social {
  text-align: center;
  margin-top: 30px;
}

.footer-social h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #333333;
  border-radius: 50%;
  color: #ffffff;
  text-decoration: none;
  transition: background-color 0.3s;
}
.mobile-social-icons{
  display: flex;
  margin-top: 8px;
}
.mobile-social-icons, a{
  margin-right: 4px;
  
}

.social-icon:hover {
  background-color: #555555;
}

.footer-copyright {
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 30px;
  }
}

/* Payment */
.payment-container {
  margin-top: 20px;
}
.radio-container {
  padding: 10px 0;
  text-align: start;
}
.radio-container input {
  font-size: 17px;
}
.radio-container label {
  padding: 0 20px;
  font-size: 17px;
}
.order-container{
  margin-top: 10px;
}
.order-detail p {
  margin-top: 2px;
}
.order-detail {
  padding: 30px 0;
  background-color: #e5fee9;
}
.order-detail h5 {
  margin-bottom: 2px;
}
.order-box {
  border-radius: 100px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.order-product {
  margin: 40px 0;
  border-bottom: 2px solid #f3f3f3;
  position: relative;
}
.order-product h4 {
  font-size: 13px;
  margin-bottom: 10px;
}
.order-product img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}
.table-bordered {
  background-color: #f3f3f3;
}
.subtotal-order button {
  width: 100%;
  padding: 15px 0;
  border: 0px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #000000;
  color: #ffffff;
}
.placeorder-container {
  margin-top: 12px;
}

/* Pagination */
.pagination .page-link {
  padding: 10px 30px;
  color: black;
}
.page-item.active .page-link {
  background-color: black;
  border: 1px solid black;
}
/* Portraits of Prominence Section */
.portraits-section {
  background-color: #000000;
  padding: 40px 20px;
  text-align: center;
}

.portraits-section h2 {
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 30px;
}

.portraits-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.portrait-item {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
}

.portrait-item img {
  width: 100%;
  height: 'full';
  object-fit: cover;
  border-radius: 5px;
}

.portrait-item p {
  margin-top: 10px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .portraits-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .portraits-grid {
    grid-template-columns: 1fr;
  }
}
/* Commissions Section CSS */
.commission-section {
  background-color: #ffffff;
  padding: 50px 20px;
  text-align: center;
}

.commission-section h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

.commission-section > p {
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.6;
  color: #666;
}

.commission-form {
  background-color: #000000;
  color: #ffffff;
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 10px;
}

.commission-form h2 {
  font-size: 24px;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #ffffff;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #ffffff;
  margin-top: 5px;
}

.form-group textarea {
  height: 100px;
  resize: vertical;
}

.submit-btn {
  background-color: #cccccc;
  color: #000000;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.submit-btn:hover {
  background-color: #bbbbbb;
}

/* Responsive Design */
@media (max-width: 768px) {
  .commission-section {
    padding: 30px 15px;
  }

  .commission-form {
    padding: 20px;
  }

  .commission-section h1 {
    font-size: 24px;
  }

  .commission-form h2 {
    font-size: 20px;
  }

  .form-group input,
  .form-group textarea {
    padding: 8px;
  }

  .submit-btn {
    padding: 8px 16px;
  }
}


/* carousel */

.carousel-item {
  height: 605px;
}

.carousel-item > div {
  position: relative;
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
}

.carousel-indicators {
  bottom: 20px;
}

.carousel-indicators button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fff;
}

.carousel-indicators .active {
  background-color: #000;
}

/*Pop-Over*/
.popover-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popover {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.popover h3 {
  margin-top: 0;
  font-size: 24px;
}

.popover input {
  font-size: 18px;
  padding: 10px;
  width: 300px;
  margin-top: 10px;
}

.popover button {
  margin-top: 10px;
}

/* About page css */
.about-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 60px;
  background-color: #111;
  color: #fff;
  margin-top: 20px;
  gap: 30px;
}

.about-content {
  max-width: 600px;
  text-align: center;
  border: 2px solid #fff;
  padding: 40px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.8);
}

.about-image img {
  width: 400px;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.5);
}

.about-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-content h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.about-content p {
  font-size: 1rem;
  line-height: 1.6;
}

.classes-section {
  padding: 40px 20px;
  background-color: #787878;
  color: #fff;
  text-align: center;
  border-radius: 15px;
  margin: 40px auto;
  width: 90%;
  max-width: 800px;
  box-shadow: 0 15px 15px 10px rgba(0, 0, 0, 0.2);
}

.classes-section h2 {
  font-size: 2.2rem;
  margin-bottom: 20px;
}

.classes-section p {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.classes-section ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  text-align: left;
}

.classes-section ul li {
  font-size: 1rem;
  margin-bottom: 10px;
}

.signup-form {
  background-color: #222;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.signup-form .form-group {
  margin-bottom: 15px;
}

.signup-form label {
  display: block;
  margin-bottom: 5px;
  color: #ccc;
}

.signup-form input,
.signup-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #555;
  border-radius: 5px;
  background-color: #444;
  color: #fff;
}

.signup-button {
  background-color: #0066cc;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.signup-button:hover {
  background-color: #004c99;
}

.error-message, .success-message {
  margin-top: 10px;
  color: #ff4d4f;
  font-weight: bold;
}

/* Media Queries */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    gap: 20px;
  }

  .about-content,
  .classes-section {
    padding: 30px;
  }

  .about-content h2,
  .about-content h3 {
    font-size: 1.8rem;
  }

  .about-content p,
  .classes-section p,
  .signup-form label {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .about-content h2 {
    font-size: 1.5rem;
  }

  .about-content h3 {
    font-size: 1.3rem;
  }

  .about-content p,
  .classes-section p {
    font-size: 0.85rem;
  }
}



/*Commisions/prints product card*/
/* Main gallery container */
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 40px 20px;
}

/* Product card */
.product-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  text-align: center;
  padding: 20px;
  
}

.product-card:hover {
  transform: scale(1.05);
}

/* Product link */
.product-link {
  text-decoration: none;
  color: inherit;
}

/* Product image */
.product-image {
  height: 250px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

/* Product name */
.product-name {
  font-size: 1.2rem;
  margin: 10px 0;
  font-weight: bold;
}

/* Product price */
.product-price {
  color: #333;
  font-size: 1rem;
  margin-bottom: 15px;
}

/* Prints gallery */
.prints-intro {
  text-align: center;
  margin: 50px 20px;
  padding: 20px;
  background: linear-gradient(135deg, #f3f3f3, #e0e0e0);
  border-radius: 15px;
  position: relative;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.prints-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: bold;
  color: #222;
  letter-spacing: 2px;
  position: relative;
  z-index: 1;
}

.prints-description {
  font-size: 1.2rem;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.7;
  color: #555;
  position: relative;
  z-index: 1;
}

/* Decorative divider under the introductory text */
.prints-divider {
  width: 100px;
  height: 4px;
  background-color: #333;
  margin: 20px auto;
  border-radius: 2px;
  position: relative;
  z-index: 1;
}

/* Background shadow styling for a dynamic look */
.prints-intro::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 50%;
  width: 120%;
  height: 120%;
  background: rgba(0, 0, 0, 0.03);
  transform: translateX(-50%) rotate(3deg);
  z-index: 0;
}


/* Enhanced Introductory text for the commissions screen */
.commissions-intro {
  text-align: center;
  margin: 40px 20px;
  padding: 30px;
  background: #fdf6e3; /* Light background color for a warm, welcoming look */
  border-radius: 12px;
  position: relative;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.commissions-title {
  font-size: 2.8rem;
  margin-bottom: 15px;
  font-weight: bold;
  color: #222;
  letter-spacing: 1.5px;
}

.commissions-description {
  font-size: 1.1rem;
  max-width: 700px;
  margin: 0 auto 20px auto;
  line-height: 1.8;
  color: #444;
  padding: 0 15px;
}

.commissions-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 30px;
  font-size: 1.1rem;
  color: #fff;
  background-color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.commissions-button:hover {
  background-color: #555; /* Darker color on hover for a dynamic effect */
}

.commissions-divider {
  width: 80px;
  height: 3px;
  background-color: #222;
  margin: 25px auto 0 auto;
  border-radius: 2px;
}
